import OpportunityService from '@/services/OpportunityService'
import useDateFilterStore from '@/store/useFiltersStore/useDateFilterStore'
import { AllMetricsKey, MetricsRequests } from '@/types/metrics'
import { OpportunityRequests } from '@/types/opportunity'
import { OpportunityItem, OpportunityItemWithMetrics } from '@/types/opportunity/Opportunity'
import { endDateParam, startDateParam, stringToDate } from '@/utils/date'
import { ONGOING_STATUS } from '@/utils/opportunityUtils'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import { shallow } from 'zustand/shallow'
import useBaseMetricFilters from '../feedback/new/useBaseMetricsFilters'
import { FeedbackListQueryParams } from '@/types/feedbacks/FeedbackRequests'
import useBasicAreaOfInterestQuery from '../areaOfInterest/useBasicAreaOfInterestQuery'
import { allMetricItems } from '@/utils/metrics'
import MetricsService from '@/services/MetricsService'
import useLogging from '../useLogging'
import FiltersService from '@/services/FiltersService'

interface Params {
  enabled: boolean
  collectionId?: string
  areaId?: string
  searchText?: string
  limit?: number
  /** currently only feedback count metrics work */
  metric?: AllMetricsKey
  onlyOngoing?: boolean
  useAppliedFilters?: boolean
  useReviewCompetitors?: boolean
  sortDirection?: 'desc' | 'asc'
}

const useOpportunitiesSortedByMetric = (
  {
    enabled = true,
    collectionId,
    searchText,
    areaId,
    limit,
    metric = 'count',
    onlyOngoing,
    useAppliedFilters,
    useReviewCompetitors,
    sortDirection = 'desc'
  }: Params = {
    enabled: true
  }
) => {
  const { logException } = useLogging({ context: 'useOpportunitiesSortedByMetric' })
  const { dateRange, datePeriod } = useDateFilterStore(
    state => ({
      dateRange: state.dateRange
        ? {
            start: startDateParam(state.dateRange.start),
            end: endDateParam(state.dateRange.end)
          }
        : null,
      datePeriod: state.datePeriod
    }),
    shallow
  )

  const { areas } = useBasicAreaOfInterestQuery({ enabled: true, collectionId })

  const queryFn = async () => {
    const params: OpportunityRequests.SearchOpportunityParams = {
      limit: limit ?? 1000,
      name: searchText
    }

    if (areaId) {
      params.area_id = [areaId]
    }

    if (collectionId) {
      params.collection_id = collectionId
    }

    const [error, response] = await OpportunityService.searchOpportunities(params)
    if (error) {
      logException(error)
      throw error
    }

    const data: OpportunityItemWithMetrics[] = (response.opportunities ?? []).map(item => ({
      id: item.opportunity_id,
      filterId: item.filter_id,
      name: item.name,
      status: item.opportunity_status_id,
      description: item.description,
      createdAt: stringToDate(item.created_at),
      new: item.new ?? false,
      createdBy: item.created_by,
      relations: item.areas,
      metrics: [],
      opportunityCount: 0,
      area: item.areas.length === 1 ? areas.find(area => area.id === item.areas[0]) : undefined
    }))

    return onlyOngoing
      ? data.filter(opportunity => ONGOING_STATUS.includes(opportunity.status))
      : data
  }

  const { data: opportunityList, isLoading: isLoadingFetching } = useQuery({
    queryKey: [
      'fetch-opportunities-to-sort',
      collectionId,
      searchText,
      areaId,
      limit,
      onlyOngoing,
      areas
    ],
    queryFn,
    enabled: enabled && areas.length > 0
  })

  const { data: baseFiltersData } = useBaseMetricFilters({
    useAppliedFilters,
    useReviewCompetitors
  })

  const baseQueryParams = useMemo(() => {
    return baseFiltersData as FeedbackListQueryParams | undefined
  }, [baseFiltersData])

  const metricItem = useMemo(() => allMetricItems[metric], [metric])

  const sortingOppsQuery = async () => {
    if (!opportunityList) return opportunityList
    if (!metricItem || metricItem.metric.name !== 'feedback_count') return opportunityList

    let startDate: string | undefined
    let endDate: string | undefined
    if (datePeriod !== 'allTime' && dateRange) {
      startDate = dateRange.start
      endDate = dateRange.end
    }

    let contexts: string[] = []

    if (!useAppliedFilters) {
      const filterAreasIdsPerCollection = (relations: string[]) => {
        // the context of the opps should be of the relations of areas present in the collection
        if (collectionId && areas) {
          const areasIds = areas.map(area => area.id)
          return relations.filter(relation => areasIds.includes(relation))
        }

        return relations
      }

      const [mergedContextError, mergedContextResponse] = await FiltersService.mergedAreas({
        areas: opportunityList.map(opp => ({
          identifier: opp.id,
          areas_ids: filterAreasIdsPerCollection(opp.relations)
        }))
      })

      if (mergedContextError) {
        logException(mergedContextError, { message: 'Failed to fetch merged areas context' })
        throw mergedContextError
      }

      contexts = mergedContextResponse.map(area => area.context)
    }

    const filterList = opportunityList.map((opportunity, index) => {
      const oppParams = {
        ...(useAppliedFilters ? baseQueryParams : {}),
        context: useAppliedFilters ? baseQueryParams?.context : contexts[index] ?? undefined,
        opportunity_id: opportunity.id
      } as FeedbackListQueryParams

      return oppParams
    })

    const metricsPayload: MetricsRequests.MetricsPayload = {
      filter_list: filterList,
      metric_list: [
        {
          name: metricItem.metric.name,
          label: metricItem.metric.label,
          args: metricItem.metric.filter,
          share_filter: metricItem.metric.share_filter,
          include_previous_value: false
        }
      ],
      posted_at_gte: startDate,
      posted_at_lt: endDate
    }

    const [error, response] = await MetricsService.opportunitiesMetrics(metricsPayload)

    if (error) {
      logException(error)
      return opportunityList
    }

    const newOpps = opportunityList.map((item, index) => {
      return {
        ...item,
        metrics: response[index]
      }
    })

    newOpps.sort((a, b) => a.name.localeCompare(b.name))

    return newOpps as OpportunityItemWithMetrics[]
  }

  const isEnabled =
    enabled && metricItem.metric.name === 'feedback_count' && (opportunityList ?? []).length > 0

  const { data, isLoading: isLoadingSorting } = useQuery({
    queryKey: [
      'fetch-metrics-to-sort',
      {
        metricItem,
        opportunityList,
        datePeriod,
        dateRange,
        useAppliedFilters,
        useReviewCompetitors,
        baseQueryParams: useAppliedFilters ? baseQueryParams : {}
      }
    ],
    queryFn: sortingOppsQuery,
    enabled: isEnabled
  })

  const opportunities: OpportunityItem[] = useMemo(() => {
    const list = [...(data ?? opportunityList ?? [])]

    list.sort((a, b) =>
      sortDirection === 'desc'
        ? (b.metrics[0]?.current_value ?? 0) - (a.metrics[0]?.current_value ?? 0)
        : (a.metrics[0]?.current_value ?? 0) - (b.metrics[0]?.current_value ?? 0)
    )

    return list
  }, [data, sortDirection, opportunityList])

  return { opportunities, isLoading: !isEnabled ? false : isLoadingFetching || isLoadingSorting }
}

export default useOpportunitiesSortedByMetric
