import * as DialogPrimitive from '@radix-ui/react-dialog'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@/components/atoms/button'
import Dialog from '@/components/atoms/dialog/Dialog'
import FlexContainer from '@/components/atoms/flex-container'
import Input from '@/components/atoms/input'
import Select, { SelectOption } from '@/components/atoms/select'
import Text from '@/components/atoms/text'
import { useUserStore } from '@/store'
import { CustomFieldMappingType } from '@/types/integrations'
import useUser from '@/hooks/useUser'
import NoPermission from '../no-permission/NoPermission'
import { capitalizeFirst } from '@/utils/letterCase'
import useCustomFieldsV2Query from './useCustomFieldsV2Query'
import useSaveCustomFieldMutation from './useSaveCustomFieldsMutation'
import Checkbox from '@/components/atoms/checkbox'
import { useTranslation } from 'react-i18next'
import i18n from '../../../plugins/i18n/i18n'

const CUSTOM_FIELDS_TYPES: CustomFieldMappingType[] = [
  'number',
  'money',
  'date',
  'datetime',
  'boolean',
  'text',
  'enum'
]

const RECORD_TYPES_OPTIONS: SelectOption[] = [
  { text: i18n.t('none'), value: '' },
  { text: i18n.t('feedback'), value: 'feedback' },
  { text: i18n.t('conversation'), value: 'conversation' },
  { text: i18n.t('conversationMessage'), value: 'conversation_message' },
  { text: i18n.t('accounts'), value: 'audience_account' },
  { text: i18n.t('users'), value: 'audience_user' }
]

interface CustomFieldFormProps {
  onOpenChange?(open: boolean): void
  open?: boolean
}

function CustomFieldFormV2(props: CustomFieldFormProps) {
  const { customFieldId } = useParams()
  const navigate = useNavigate()

  const currentUser = useUserStore(state => state.currentUser)
  const { t } = useTranslation()

  const { customFields } = useCustomFieldsV2Query({ enabled: true })

  const customField = customFields.find(field => field.id === customFieldId)
  const isEdit = Boolean(customField)

  function close(open: boolean) {
    if (props.onOpenChange) {
      props.onOpenChange(open)
    } else {
      navigate('/settings/custom_fields')
    }
  }

  const mutation = useSaveCustomFieldMutation({
    isEdit,
    customFieldId,
    onSuccess: () => close(false)
  })
  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()

    mutation.mutate(new FormData(event.currentTarget))
  }

  const { userPermissions } = useUser()
  const isManager = userPermissions.source.includes('manager')

  const onNameInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const regex = /[^a-zA-Z0-9_/-]/g
    if (regex.test(e.key)) e.preventDefault()
  }

  // const [selectedRecordType, setSelectedRecordType] = useState<string | undefined>(undefined)
  // const onRecordTypeChange = (value: string) => {
  //   if (value === 'none') {
  //     setSelectedRecordType(undefined)
  //     return
  //   }
  //   setSelectedRecordType(value)
  // }

  if (!isManager && currentUser) {
    return <NoPermission />
  }

  return (
    <Dialog
      align="right"
      closeIcon
      modal
      onOpenChange={close}
      open={props.open ?? true}
      useFullHeight
    >
      <FlexContainer
        as="form"
        fullHeight
        css={{ gap: 24, padding: 32, overflowY: 'auto' }}
        direction="column"
        onSubmit={onSubmit}
      >
        <FlexContainer direction="column" gap="micro">
          <Text as="h1" typeface="titleBoldXXS">
            {isEdit ? t('edit') : t('createNew')} {t('customField')}
          </Text>
          <Text as="p" typeface="subtitleRegularNano">
            {t('thisFieldWillBeCreatedAndCanBeUsedInAnyFieldGroup')}
          </Text>
        </FlexContainer>
        <FlexContainer direction="column" gap="micro">
          <Text as="label" htmlFor="customFieldFromName" typeface="subtitleRegularXXXS">
            {t('matchingColumnName')}
          </Text>
          <Input
            defaultValue={customField?.from}
            id="customFieldFromName"
            name="from"
            placeholder={t('nameOfTheExistingColumnsInYourCsv')}
            required
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('typeOfTheRecord')}
          </Text>
          <Select
            contentAlign="start"
            defaultValue={customField?.record_type}
            label="Record type"
            name="recordType"
            options={RECORD_TYPES_OPTIONS}
            placeholder={t('selectRecordType')}
            // onValueChange={onRecordTypeChange}
            // value={selectedRecordType}
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" htmlFor="customFieldSource" typeface="subtitleRegularXXXS">
            {t('sourceType')}
          </Text>
          <Input
            defaultValue={customField?.source}
            id="customFieldSource"
            name="source"
            placeholder={t('sourceTypeOfTheField')}
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" htmlFor="customFieldName" typeface="subtitleRegularXXXS">
            {t('giveANameToYourField')}
          </Text>
          <Input
            defaultValue={customField?.to.name ?? undefined}
            id="customFieldName"
            name="name"
            onKeyDown={onNameInputKeyDown}
            placeholder={t('fieldName')}
            required
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text as="label" htmlFor="customFieldDescription" typeface="subtitleRegularXXXS">
            {t('description')}
          </Text>
          <Input
            as="textarea"
            css={{ height: 'auto' }}
            defaultValue={customField?.to.description}
            id="customFieldDescription"
            name="description"
            placeholder={t('giveMoreDetails')}
            required
            rows={4}
          />
        </FlexContainer>
        <FlexContainer direction="column" gap="micro">
          <Text as="label" typeface="subtitleRegularXXXS">
            {t('fieldType')}
          </Text>
          <Select
            contentAlign="start"
            defaultValue={customField?.to.type}
            label={t('fieldType')}
            name="type"
            options={CUSTOM_FIELDS_TYPES.map(type => ({
              text: capitalizeFirst(type),
              value: type
            }))}
            placeholder={t('selectType')}
            required
          />
        </FlexContainer>

        <FlexContainer direction="column" gap="micro">
          <Text typeface="subtitleRegularXXXS">Accept list of values?</Text>
          <Checkbox
            defaultChecked={customField?.to.repeated}
            name="repeated"
            text={'Yes/No'}
            value="repeated"
          />
        </FlexContainer>

        <FlexContainer direction="rowReverse" gap="xxs">
          <Button disabled={mutation.isLoading} type="submit">
            {mutation.isLoading ? t('saving') : t('save')}
          </Button>
          <DialogPrimitive.Close asChild>
            <Button css={{ color: '$neutralLowPure' }} type="button" variant="flat">
              {t('cancel')}
            </Button>
          </DialogPrimitive.Close>
        </FlexContainer>
      </FlexContainer>
    </Dialog>
  )
}

export default CustomFieldFormV2
