import useNumberAdvancedFilter from '@/hooks/advancedFilters/useNumberAdvancedFilter'
import { NumberFilterType } from '@/types/filters/AdvancedFilters'
import { snakeToTitle } from '@/utils/letterCase'
import { useState } from 'react'
import { FilterItemDropdown } from '../../AppliedFilters/FilterItem'
import FiltersSkeleton from '../../FiltersSidebar/AddFilterMenu/FiltersSkeleton'
import { NumericFilterContent } from '../../FiltersSidebar/NumericGenericFilter'
import { NumericRangeOption } from '@/types/filters/Filters'
import { useTranslation } from 'react-i18next'
import i18n from '../../../../../plugins/i18n/i18n'

const defaultLabels: Record<NumericRangeOption, string> = {
  all: 'All values',
  between: 'Range',
  is: 'Exactly value',
  null: 'Empty values'
}

interface Props {
  title: string
  name: string
  path: string
  value: { $gte: number; $lte: number } | null
  index: number
  type: NumberFilterType
  disabled?: boolean
}

const NumberFilterApplied = ({
  title,
  name,
  path,
  value,
  index,
  type,
  disabled = false
}: Props) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const initialValue = (value ? [value.$gte, value.$lte] : null) as [number, number] | null

  const {
    exactValue,
    exactValueText,
    isExactValueOpen,
    isLoading,
    isRangeOpen,
    max,
    min,
    update,
    onOptionChange,
    onRangeChange,
    onValueChange,
    rangeText,
    rangeValue,
    selectedOption
  } = useNumberAdvancedFilter({
    name,
    path,
    enableQuery: open,
    type,
    index,
    initialValue
  })

  let shortText = t('empty')
  if (value) {
    shortText = `${value.$gte} — ${value.$lte}`
    if (value.$gte === value.$lte) {
      shortText = `${value.$gte}`
    }
  }

  let longText = i18n.t('emptyText', { escapeValue: false })

  if (value) {
    longText = i18n.t('betweenValues', { gte: value.$gte, lte: value.$lte })
    if (value.$gte === value.$lte) {
      longText = `${value.$gte}`
    }
  }

  const tooltipText = i18n.t('tooltipText', {
    name: snakeToTitle(name),
    longText,
    escapeValue: false
  })

  return (
    <FilterItemDropdown
      buttonDisabled={disabled}
      content={shortText}
      contentType="label"
      disabled={isLoading || disabled}
      dropdown={
        <>
          <NumericFilterContent
            exactValue={exactValue}
            exactValueText={exactValueText}
            isExactValueOpen={isExactValueOpen}
            isRangeOpen={isRangeOpen}
            labels={defaultLabels}
            max={max}
            min={min}
            onOptionChange={onOptionChange}
            onRangeChange={onRangeChange}
            onValueChange={onValueChange}
            rangeText={rangeText}
            rangeValue={rangeValue}
            selectedOption={selectedOption}
          />
          {isLoading && <FiltersSkeleton />}
        </>
      }
      onApply={update}
      onOpenChange={setOpen}
      open={open}
      title={title}
      tooltip={tooltipText}
    />
  )
}

export default NumberFilterApplied
